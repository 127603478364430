import React, { useEffect, useState } from "react";
import { Button, DateRangePicker, CheckPicker, Icon, Placeholder } from "rsuite";
import "@asseinfo/react-kanban/dist/styles.css";
import "./KanbanPage.css";

import { io } from "../../utilities/io";
import "@asseinfo/react-kanban/dist/styles.css";
import dayjs from "dayjs";
//@ts-ignore
import Board, { moveCard } from "@asseinfo/react-kanban";
import GlobalLoading from "../../components/globalLoading";
import axios from "../../utilities/axios";
import { InputSelect } from "../../components/InputSelect";
import "../../../src/style/App.css";

const KanbanLtlPage = () => {
    const [board, setBoard] = useState<any>({ columns: [] });
    const [users, setUsers] = useState<any>([]);
    const [filter, setFilter] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>([])

    const statusMap: { [key: number]: { name: string; color: string } } = {
        1: { name: "EM ABERTO", color: "#FFCC00" },
        2: { name: "APROVADO", color: "#4CAF50" },
        4: { name: "PENDENTE DOCUMENTAÇÃO", color: "#FF9800" },
        5: { name: "DOCUMENTOS VALIDADOS", color: "#8BC34A" },
        6: { name: "DTA PARAMETRIZADA", color: "#98d32b" },
        7: { name: "DECLINADO", color: "#F44336" },
        8: { name: "EM PROCESSO DE CARREGAMENTO PROGRAMADO", color: "#3F51B5" },
        9: { name: "EM VIAGEM", color: "#2196F3" },
        10: { name: "AGUARDANDO VALIDAÇÃO", color: "#FFC107" },
        11: { name: "DOCUMENTAÇÃO REJEITADA", color: "#D32F2F" },
        12: { name: "CHEGADA NO DESTINO", color: "#8BC34A" },
        13: { name: "ORÇ. EXPIRADO", color: "#795548" },
        14: { name: "TRÂNSITO CONCLUÍDO", color: "#009688" },
        15: { name: "APROVADOS", color: "#4CAF50" },
        16: { name: "DECLINADOS", color: "#F44336" },
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get("/api/v1/kanban-get", { params: { ...filter } });
            const transformedData = {
                columns: response.data.columns.map((column: any) => ({
                    id: column.id,
                    title: column.title,
                    cards: column.cards || [],
                })),
            };
            setBoard(transformedData);
        } catch (error) {
            console.error("Error fetching kanban data", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get("/api/v1/users/select");
            setUsers(response.data.items);
        } catch (error) {
            console.error("Error fetching users data", error);
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchData();

        document.body.classList.add("kanban-no-scroll");

        // Remover a classe quando o componente for desmontado
        return () => {
            document.body.classList.remove("kanban-no-scroll");
        };
    }, []);
    const getData = async () => {
        setIsLoading(true); // Ativar o loading antes de iniciar a requisição
        try {
            const response = await axios.get("/api/v1/kanban-get", {
                params: {
                    ...filter,
                },
            });

            const transformedData = {
                columns: response.data.columns.map((column: any) => ({
                    id: column.id,
                    title: column.title,
                    cards: column.cards || [],
                })),
            };

            setBoard(transformedData); // Atualiza os dados do board
        } catch (error) {
            console.error("Error fetching kanban data", error);
        } finally {
            setIsLoading(false); // Desativa o loading após a finalização da requisição
        }
    };

    const getUsersData = async () => {

        axios.get("/api/v1/users/select", {
            params: {
                ...filter
            }
        }).then((res: any) => {
            setUsers(res.data.items)
        }).finally(() => {
        })
    }

    const typingFilter = (field: any, value: any) => {
        var init;
        var finish;
        if (field == "deate") {
            init = value?.[0]
            finish = value?.[1]
            setFilter({ ...filter, init, finish })
        } else if (field == "budget_fk_user_id") {
            setFilter({ ...filter, budget_fk_user_id: value.toString() })
        }
        else {
            setFilter({ ...filter, [field]: value })

        }
    }

    const onCardDragEnd = (card: any, source: any, destination: any) => {
        const updatedBoard = moveCard(board, source, destination);
        setBoard(updatedBoard);
    };

    const handleCardClick = (card: any) => {
        // console.log("Card clicado:", card);
        window.open('https://' + window.location.host + '/dashboard/orcamentos/budgets/' + card.id, "_blank");
        // Aqui você pode adicionar outras ações, como navegação ou abrir um modal
    };


    const formatCpfCnpj = (value: string | any) => {
        if (!value) return '';

        const cleanedValue = value.replace(/\D/g, '');

        if (cleanedValue.length === 11) {
            return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (cleanedValue.length === 14) {
            return cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
    };

    const renderCard = (card: any) => {
        // Verifica se o card é um placeholder
        if (card.isPlaceholder) {
            return (
                <div
                    className="react-kanban-card"
                    style={{
                        width: "303px",
                        boxSizing: "border-box",
                        textAlign: "center",
                        fontStyle: "italic",
                        color: "#888",
                        backgroundColor: "#f9f9f9",
                    }}
                >
                    Nenhum registro nessa situação
                </div>
            );
        }

        // Renderiza o card normal
        const status = statusMap[card.budget_fk_status_budget_id] || { name: "Desconhecido", color: "#000" };
        return (
            <div
                className="react-kanban-card"
                style={{ width: "100%", boxSizing: "border-box" }}
                onClick={() => handleCardClick(card)}
            >
                <div style={{
                    backgroundColor: status.color,
                    color: "#fff",
                    padding: "2px 8px",
                    borderRadius: "4px",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    marginTop: "-4px",
                    position: "relative",
                    top: "4px",
                }}>{card.budget_code}</div>
                <div className="react-kanban-card-details">
                    <p>
                        <strong>Cliente:</strong> {card.cliente}
                    </p>
                    <p>
                        <strong>CPF / CNPJ:</strong> {formatCpfCnpj(card.budget_cpfcnpj)}
                    </p>
                    <p>
                        <strong>Status: </strong>
                        <div style={{
                            backgroundColor: "grey",
                            color: "#fff",
                            padding: "2px 8px",
                            borderRadius: "4px",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                            marginTop: "-4px",
                            position: "relative",
                            top: "4px",
                        }}>{status.name}</div>
                    </p>
                    <p>
                        <strong>Data:</strong> {dayjs(card.budget_created_at).format("DD/MM/YYYY HH:mm")}
                    </p>
                </div>
            </div>
        );
    };

    const renderColumnHeader = (column: { title: string }) => {
        const match = column.title.match(/^(.*)\s\[\s(\d+)\s\]$/);
        const titleText = match ? match[1] : column.title;
        const count = match ? match[2] : "";
        const status = Object.values(statusMap).find((s) => s.name === titleText) || { color: "#ccc" };

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: status.color,
                    color: "#fff",
                    padding: "8px 12px",
                    borderRadius: "4px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: "bold",
                }}
                className="react-kanban-column-header"
            >
                <span style={{ flex: "1", overflow: "hidden", textOverflow: "ellipsis" }}>{titleText}</span>
                {count && (
                    <span
                        style={{
                            marginLeft: "8px",
                            backgroundColor: "#fff",
                            color: status.color,
                            padding: "2px 8px",
                            borderRadius: "50%",
                            fontSize: "12px",
                            fontWeight: "bold",
                            lineHeight: "20px",
                            display: "inline-block",
                            minWidth: "20px",
                            textAlign: "left",
                        }}
                    >
                        {count}
                    </span>
                )}
            </div>
        );
    };


    return (
        <div className="kanban-container">
            <div style={{ display: "flex", width: "100%", gap: "16px", alignItems: "flex-end" }}>
                {/* Campo de Data */}
                <div style={{ flex: "1" }}>
                    <label style={{ display: "block", marginBottom: "4px", fontWeight: "bold" }}>
                        Data de Criação do Orçamento
                    </label>
                    <DateRangePicker
                        onChange={(value: any) => typingFilter("deate", value)}
                        format={"DD/MM/YYYY"}
                        style={{ width: "100%" }}
                    />
                </div>

                {/* Campo de Cliente */}
                <div style={{ flex: "1.5" }}>
                    <label style={{ display: "block", marginBottom: "4px", fontWeight: "bold" }}>
                        Cliente
                    </label>
                    <CheckPicker
                        placeholder="Selecione..."
                        style={{ width: "100%" }}
                        onChange={(value: any) => typingFilter("budget_fk_user_id", value)}
                        data={users ?? []}
                    />
                </div>

                {/* Botão */}
                <div style={{ flex: "none" }}>
                    <Button
                        onClick={() => getData()}
                        style={{ width: "130px", height: "40px" }}
                        appearance="primary"
                    >
                        <Icon icon={"search"} /> FILTRAR
                    </Button>
                </div>
            </div>

            {isLoading && (
                <div style={{ width: "100%" }}>
                    <Placeholder.Paragraph rows={12} />
                </div>
            )}

            {isLoading ? (
                <div>Carregando...</div>
            ) : (
                <Board
                    disableColumnDrag
                    disableCardDrag
                    renderColumnHeader={renderColumnHeader}
                    renderCard={(card: any) => renderCard(card)}
                >
                    {{
                        ...board,
                        columns: board.columns.map((column: any) => ({
                            ...column,
                            cards: column.cards.length
                                ? column.cards // Se houver cards, renderiza-os normalmente
                                : [
                                    {
                                        id: `placeholder-${column.id}`, // Um ID único para o placeholder
                                        isPlaceholder: true, // Marcação para diferenciar o card
                                    },
                                ],
                        })),
                    }}
                </Board>
            )}
        </div>
    );

};

export default KanbanLtlPage;
