import { Event } from "../Event";
import axios from "../../utilities/axios";

export class BudgetFkCompanyIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ['budget_fk_param_id']);
        if (value) {
            axios.get(`/api/v1/get-companies/${value}`).then((res: any) => {
                // console.log("res :>", res)
                if (res?.data?.getInfos?.company_id) {
                    const { company_cpf_cnpj, company_fantasy_name, company_email } = res?.data?.getInfos
                    this.setFieldValue("budget_cpfcnpj", company_cpf_cnpj)
                    this.setFieldValue("budget_client_name", company_fantasy_name)
                    this.setFieldValue("budget_client_email", company_email)
                }
            })
        }
        setTimeout(() => {
            this.setState("loadings", []);
        }, 500);
    }
}