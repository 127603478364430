import axios from "../../utilities/axios";
import { Event } from "../Event";
// import { ConsultaCNPJ } from "./ConsultaCNPJ";

export class CompanyCpfCnpjEvents extends Event {
    //init onFocusOut
    public onBlur = async (value: any, setField: any, setState: any) => {
        if (value) {
            this.setState("loadings", [
                "company_email",
                "company_phone_number",
                "company_address_complement",
                "company_address_neighborhood",
                "company_address_number",
                "company_address_street",
                // "company_fk_street_type_id",
                "company_fk_city_id",
                "company_fk_state_id",
                "company_fantasy",
                "company_abbreviation",
                "company_name",
                "company_cep",
                "company_cpf_cnpj",
                "company_fantasy_name"
            ]);
            // this.setState({ loadings: ["company_cpf_cnpj"] });
         await axios
                .get("/api/v1/exists/companies/company_cpf_cnpj", { params: { value, id: this.globalState.formValues.company_id } })
                .then((res) => {
                    // this.setState({
                    //     loadings: [],
                    // });
                    setState("loadings", []);
                    this.setState({
                        errors: {
                            company_cnpj: "Esse CNPJ já existe!",
                        },
                    });
                })
                .catch(async (e) => {
                    await axios
                        .get("/api/v1/consulta-cnpj/" + value)
                        .then(async (res) => {
                            var { data } = res.data;
                            if (data.status === "ERROR") {
                                setState("loadings", []);
                                return;
                            }
                            if (data) {
                                if (data.fantasia.length) {
                                    setField("company_fantasy", data.fantasia.length ? data.fantasia : data.nome);
                                }
                                var stateId: any = undefined;
                                setField("company_name", data.nome);
                                setField("company_fantasy_name", data.fantasia);
                                setField("company_cep", data.cep.split(".").join(""));
                                await axios
                                    .get("/api/v1/states/select", {
                                        params: {
                                            state: data.uf,
                                        },
                                    })
                                    .then(async (res) => {
                                        var { items } = res.data;
                                        const { label, value } = items[0] ?? undefined;
                                        if (label && value) {
                                            setField("company_fk_state_id", { label, value: value.toString() });
                                            // setField("company_fk_state_id", value);

                                            await axios
                                                .get("/api/v1/cities/select", {
                                                    params: {
                                                        search: data.municipio,
                                                        state: value,
                                                    },
                                                })
                                                .then((responseCity) => {
                                                    const { items } = responseCity.data;
                                                    // console.log({ items })
                                                    const { label, value } = items[0] ?? undefined;
                                                    if (label && value) {
                                                        // setField("company_fk_city_id", { label, value: value.toString() });
                                                        setField("company_fk_city_id", value);
                                                    }
                                                })

                                        }
                                    })
                                    .catch((e) =>{
                                        setState("loadings", []);
                                    })
                                    .finally(() => {
                                        setTimeout(() => {
                                            setState("loadings", []);
                                        }, 2000)
                                    })
                                // if (data.logradouro.includes("AV") && data.logradouro[0].toLocaleUpperCase() !== "R") {
                                //     setField("company_fk_street_type_id", { label: "AVENIDA", value: "2" });
                                // } else {
                                //     setField("company_fk_street_type_id", { label: "RUA", value: "1" });
                                // }
                                setField("company_address_street", data.logradouro);
                                setField("company_address_number", data.numero);
                                setField("company_address_neighborhood", data.bairro);
                                setField("company_address_complement", data.complemento);
                                setField("company_phone_number", data.telefone.split(" ").join(""));
                                setField("company_email", data.email);
                            }
                        })
                        .catch((e) => {
                            setState("loadings", []);
                        })
                        .finally(() => { });
                })
                .finally(() => { });
        }



    };
    //end

    // public render = () => {
    // return <ConsultaCNPJ />
    // };
}
