import axios from "../../../utilities/axios";
import { Event } from "../../Event";

export class BudgetAcceptUseRegisterEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'budget_accept_client_cnpj_charge',
            'budget_accept_corporate_reason_charged',
            'budget_accept_cep',
            'budget_accept_public_place',
            'budget_accept_address_number',
            'budget_accept_neighborhood',
            'budget_accept_address_complement',
            'budget_accept_fk_state_id',
            'budget_accept_fk_city_id'
        ]);
        if (value) {
            axios
                .get(`/api/v1/companies/${this.globalProps.parentValues.budget_fk_company_id}`)
                .then((subRes: any) => {
                    var subData = subRes.data.item;
                    console.log({subData})
                    this.setFieldValue('budget_accept_client_cnpj_charge', subData?.company_cpf_cnpj);
                    this.setFieldValue('budget_accept_corporate_reason_charged', subData?.company_name);
                    this.setFieldValue('budget_accept_cep', subData?.company_cep);
                    this.setFieldValue('budget_accept_public_place', subData?.company_address_street);
                    this.setFieldValue('budget_accept_address_number', subData?.company_address_number);
                    this.setFieldValue('budget_accept_neighborhood', subData?.company_address_neighborhood);
                    this.setFieldValue('budget_accept_address_complement', subData?.company_address_complement);
                    this.setFieldValue('budget_accept_fk_state_id', subData?.company_fk_state_id);
                    this.setFieldValue('budget_accept_fk_city_id', subData?.company_fk_city_id);
                })
                .finally(() => {
                    this.setState('loadings', []);
                })
        } else {
            setTimeout(() => {
                this.setState('loadings', []);
            }, 500);
        }
    }
}