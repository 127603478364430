import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col } from "rsuite";
import { Event } from "../Event";
import { withRouter } from "react-router";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
import { CustomModal } from "../../components/customModal/CustomModal"
import classNames from "classnames";
import { link } from "fs";
import { BudgetAcceptCustom } from "../../components/formfields/custom/budget/BudgetAcceptCustom";
import { BudgetActions } from '../../components/formfields/custom/budget/BudgetActions'
import _ from "lodash";
import { UserContext } from "../../components/authProvider/main";

class BudgetFooterComponent extends React.Component<any, {}> {
    static contextType = UserContext;

    public state = {
        showModal: false,
        showModalSubmit: false,
        showModalDecline: false,
        show: false,
        showEmail: false,
        loading: false,
        showSituation: false,
        situacaoModal: "",
        started: false,
        loadingClockIn: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false
    };

    print = () => {
        if (this.props?.values?.formValues?.budget_id) {
            this.setState({ loading: true })
            axios.get("/api/v1/print-budget/" + this.props?.values?.formValues?.budget_id).then((res) => {
                if (res.data && res.data.url) {
                    window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                }
                // Notification.success({
                //     title: "Serviço Clonado!",
                //     description: `O Serviço #${this.props?.values?.formValues?.param_id} Foi Clonado com Sucesso!`
                // })
                // this.props.history.push("/dashboard/configuracoes/params/"+res.data)
            }).catch((e) => {
                throw ("Erro")
            }).finally(() => {
                this.setState({ loading: false });
            });
        } else {
            throw ("Erro")
        }
    }

    changeSituation = async ({ nextSituation }: { nextSituation: number }) => {
        this.setState({ loading: true })
        let isValidChange = 0;

        if (nextSituation == 6 && ([5, 6]).includes(this.props?.values?.formValues?.budget_fk_charging_type_id)) {
            Notification.warning({
                title: 'Atenção',
                description: 'Não é possível avançar para DTA Parametrizada se o carregamento é LTL!',
                duration: 0
            })
            isValidChange = 1
            this.setState({ loading: false })
        }

        if (nextSituation == 16 && ![5, 6].includes(this.props?.values?.formValues?.budget_fk_charging_type_id)) {
            Notification.warning({
                title: 'Atenção',
                description: 'Não é possível avançar para Concluído se o carregamento não é LTL!',
                duration: 0
            })
            isValidChange = 1
            this.setState({ loading: false })
        }

        if (nextSituation == 15 && ([5, 6]).includes(this.props?.values?.formValues?.budget_fk_charging_type_id) && (['', null, undefined]).includes(this.props?.values?.formValues?.budget_process_shipping)) {
            Notification.warning({
                title: 'Atenção',
                description: 'É necessário preencher a Data do Processo de Carregamento!',
                duration: 0
            })
            isValidChange = 1
            this.setState({ loading: false })
        }

        if (nextSituation == 16 && ([5, 6]).includes(this.props?.values?.formValues?.budget_fk_charging_type_id) && (['', null, undefined]).includes(this.props?.values?.formValues?.budget_date_finished)) {
            Notification.warning({
                title: 'Atenção',
                description: 'É necessário preencher a Data de Conclusão!',
                duration: 0
            })
            isValidChange = 1
            this.setState({ loading: false })
        }
        if (nextSituation !== 11 && nextSituation !== 7 && nextSituation !== 2 && nextSituation !== 1 && isValidChange == 0) {
            await axios
                .get(`/api/v1/budget/verifyDocuments`, {
                    params: {
                        budgetId: this.props?.values?.formValues?.budget_id,
                        chargingType: this.props?.values?.formValues?.budget_fk_charging_type_id
                    }
                })
                .then((response) => {
                    if (response?.data[0]) {
                        Notification.warning({
                            title: 'Atenção',
                            description: (
                                <div dangerouslySetInnerHTML={{ __html: response?.data[0] }} />
                            ),
                            duration: 0
                        })
                        isValidChange = 1
                    }
                }).catch((e) => {
                    Notification.warning({
                        title: 'Atenção',
                        description: e.response.data.message
                    })
                }).finally(() => {
                    this.setState({ loading: false })
                })
        }

        if (isValidChange == 0) {
            this.props.onSubmit(
                async () => {
                    await axios
                        .get(`/api/v1/budget/situation`, {
                            params: {
                                nextSituation: nextSituation,
                                budgetId: this.props?.values?.formValues?.budget_id
                            }
                        })
                        .then((response) => {
                            console.log({ response })
                            if (response.data.status == 200) {
                                setTimeout(() => {
                                    // Redireciona para a listagem
                                    if (nextSituation == 2) {
                                        // Definir no localStorage que a notificação precisa ser exibida
                                        localStorage.setItem('showNotification', 'true');
                                    }
                                    window.location.href = "/dashboard/orcamentos/budgets";
                                }, 1500);


                                // Espera o redirecionamento e exibe a notificação após 3 segundos
                                // if (nextSituation == 2) {
                                //     setTimeout(() => {
                                //         Notification.warning({
                                //             title: 'Atenção',
                                //             description: "Atenção! Edite o orçamento, clique em Verificar Documentação no final da página para enviar os documentos necessários para prosseguir com o orçamento.",
                                //             duration: 0,
                                //         });
                                //     }, 3000);
                                // }
                            }
                        })
                        .catch((e) => {
                            Notification.warning({
                                title: 'Atenção',
                                description: e.response.data.message
                            })
                        })
                        .finally(() => {
                            this.setState({ loading: false })
                        })
                }
            )
        }
    }

    recuseDocumentation = () => {
        const { formValues } = this.props.values
        const checkedIfExistFollowUp = _.find(formValues.budget_followups, { 'budget_followup_documentation_is_reject': true }) ? true : false;
        if (!checkedIfExistFollowUp) {
            Notification.warning(
                {
                    title: 'Atenção!',
                    description: 'Para a rejeição é necessario adicionar um motivo ao FollowUp'
                }
            )
            return
        }
        this.props.onSubmit(
            () => {
                this.changeSituation({ nextSituation: 11 })
            }
        )
    }

    decline = () => {
        this.props.onSubmit(
            async () => {
                this.changeSituation({ nextSituation: 7 })
            }
        );
    }


    onSubmit = async () => {
        if (this.props.values.formValues.budget_is_stackable) {
            this.setState({ showModalSubmit: true });
        } else {
            this.props.onSubmit()
        }
    };

    onConfirmSave = () => {

        this.props.onSubmit()
    }

    render() {
        // { console.log("this :>÷ 12122121", this) }

        return (
            <div
                style={
                    {
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 4
                    }
                }
            >
                <CustomModal
                    title={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 10
                            }}
                        >
                            <div>
                                Lembrete
                            </div>
                            <i className="fas fa-exclamation-circle"></i>
                        </div>
                    }
                    confirmColor="green"
                    onConfirm={this.onConfirmSave}
                    closeModal={() => this.setState({ showModalSubmit: false })}
                    onCancel={() => this.setState({ showModalSubmit: false })}
                    show={this.state.showModalSubmit}
                    body={
                        <p
                            style={
                                {
                                    fontWeight: 800
                                }
                            }
                        >
                            Durante a operação, caso identificado impossibilidade de remonte será cobrado taxa extra referente a Não Empilhamento
                        </p>
                    }
                />
                <CustomModal
                    title={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 10
                            }}
                        >
                            <div>
                                Aviso
                            </div>
                            <i className="fas fa-exclamation-triangle"></i>
                        </div>
                    }
                    confirmColor="green"
                    onConfirm={this.decline}
                    closeModal={() => this.setState({ showModalDecline: false })}
                    onCancel={() => this.setState({ showModalDecline: false })}
                    show={this.state.showModalDecline}
                    body={
                        <p
                            style={
                                {
                                    fontWeight: 800
                                }
                            }
                        >
                            Tem certeza que deseja declinar orçamento?
                        </p>
                    }
                />
                {/* {console.log("this.props :>", this)} */}
                {
                    (![10, 5, 6, 8, 9, 12, 13, 14, 15, 16].includes(this.props.values.formValues.budget_fk_status_budget_id) ||
                        this.context.data.user_fk_role_id === 2) && (
                        < Button color="green" onClick={this.onSubmit} loading={this.state.loading}>
                            <i className="fas fa-fw fa-save mr-2"></i>
                            Gravar
                        </Button>
                    )
                }
                <Button loading={this.state.loading} onClick={() => this.print()} color={"blue"} className="ml-4">
                    <i className="fas fa-copy fa-fw mr-2"></i>
                    Imprimir Orçamento
                </Button>
                {
                    this.props.values.formValues.budget_fk_status_budget_id == 1 && (
                        <>
                            <Button loading={this.state.loading} onClick={() => this.changeSituation({ nextSituation: 2 })} color={"blue"} className="ml-4">
                                <i className="fas fa-check-double mr-2"></i>
                                Aprovar
                            </Button>
                            <Button
                                appearance="primary"
                                color="red"
                                onClick={() => this.setState({ showModalDecline: true })}
                            >
                                <i className="fas fa-window-close mr-2" ></i>
                                Declinar
                            </Button>
                        </>
                    )
                }

                {
                    this.props.values.formValues.budget_fk_status_budget_id == 2 && (
                        <>
                            <Button loading={this.state.loading} onClick={() => this.changeSituation({ nextSituation: 1 })} color={"red"} className="ml-4">
                                <i className="fas fa-check-double mr-2"></i>
                                Desfazer Aprovação
                            </Button>
                        </>
                    )
                }
                {
                    [4, 11].includes(this.props.values.formValues.budget_fk_status_budget_id) && (
                        <Button
                            loading={this.state.loading}
                            color="blue"
                            style={
                                {
                                    display: 'flex',
                                    flex: 'row',
                                    gap: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }
                            }
                            onClick={() => this.changeSituation({ nextSituation: 10 })}
                        >
                            <i className="fas fa-spell-check"></i>
                            Documentação Completa
                        </Button>
                    )
                }
                {
                    (this.props.values.formValues.budget_fk_status_budget_id) == 10 && (this.context.data.user_fk_role_id !== 6) && (
                        <>
                            <Button
                                loading={this.state.loading}
                                color="green"
                                style={
                                    {
                                        display: 'flex',
                                        flex: 'row',
                                        gap: 10,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }
                                }
                                onClick={() => this.changeSituation({ nextSituation: 5 })}
                            >
                                <i className="fas fa-spell-check"></i>
                                Documentos Validados
                            </Button>
                            <Button
                                loading={this.state.loading}
                                color="red"
                                style={
                                    {
                                        display: 'flex',
                                        flex: 'row',
                                        gap: 10,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }
                                }
                                onClick={() => this.recuseDocumentation()}
                            >
                                <i className="fas fa-times"></i>
                                Documentos Rejeitados
                            </Button>
                        </>
                    )
                }
                {
                    [5, 6, 8, 9, 12, 14, 15, 16].includes(this.props.values.formValues.budget_fk_status_budget_id) && (
                        <>
                            <BudgetActions
                                changeSituation={this.changeSituation}
                            />
                        </>
                    )
                }
                {
                    ![1, 7].includes(this.props.values.formValues.budget_fk_status_budget_id) && (
                        <BudgetAcceptCustom
                            ButtonOpenName="Verificar documentação"
                            onSubmit={this.props.onSubmit}
                            valuesProps={this.props.values}
                            icon={<i className="fas fa-file-alt"></i>}
                        />
                    )
                }

            </div >
        )
    }
}

const BudgetFooterWithRouter = withRouter(BudgetFooterComponent);


class BudgetFooterComponentAdd extends React.Component<any, {}> {
    public state = {
        loading: false,
        showModalSubmit: false
    };

    onSubmit = () => {
        // console.log("this.props:>", this)
        if (this.props.values.formValues.budget_is_stackable) {
            this.setState({ showModalSubmit: true });
        } else {
            this.props.onSubmit()
        }
    };

    onConfirmSave = () => {
        this.props.onSubmit()
    }
    render(): React.ReactNode {
        return (
            <>
                <CustomModal
                    title={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 10
                            }}
                        >
                            <div>
                                Lembrete
                            </div>
                            <i className="fas fa-exclamation-circle"></i>
                        </div>
                    }
                    confirmColor="green"
                    onConfirm={this.onConfirmSave}
                    closeModal={() => this.setState({ showModalSubmit: false })}
                    onCancel={() => this.setState({ showModalSubmit: false })}
                    show={this.state.showModalSubmit}
                    body={
                        <p style={{ fontWeight: 800 }}>
                            Durante a operação, caso identificado impossibilidade de remonte será cobrado taxa extra referente a Não Empilhamento
                        </p>
                    }
                />

                <Button color="green" onClick={this.onSubmit} loading={this.state.loading}>
                    <i className="fas fa-fw fa-save mr-2"></i>
                    Gravar
                </Button>
            </>
        );
    }
}
const BudgetFooterAddWithRouter = withRouter(BudgetFooterComponentAdd);


export class BudgetFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <BudgetFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };

    public onRenderAddFooter = (onSubmit: any) => {
        return (
            <>
                <BudgetFooterAddWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    }
}