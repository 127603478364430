import { Event } from "../Event";

export class BudgetIsChemicalEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (formValues.budget_is_anvisa && value === true) {
            this.setField('budget_fk_chemical_class_id', {
                readonly: false,
                required: true
            });
        }
    }
}