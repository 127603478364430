//IMPORTAR O COMPONENTE
import { CreateLambdaComplement } from "./CreateLambdaComplement";
import { CreateTablesComplement } from "./CreateTablesComplement";
import { TableComplement } from "./TableComplement";
import { TableGridLambda } from "./TableGridLambda";
import { ValidatePage } from './ValidatePage'
import { TableNewDownloader } from './TableNewDownloader'
import { TransferRequestCustom } from "./transferRequest/TransferRequestCustom";
import { ReportCustom } from "./ReportCustom";
import { CheckTreePickerUserRestrict } from './user/CheckTreePickerUserRestrict'
import { SelectCustom } from "./SelectCustom";
import { CheckTreePickerProjectUser } from "./project/CheckTreePickerProjectUser";
import { TransferRequestCollectUser } from "./transferRequest/TransferRequestCollectUser";
import { TransferRequestUser } from "./transferRequest/TransferRequestUser";
import { GraphicCustom } from "./GraphicCustom";
import { TermsAccept } from "./TermsAccept";
import { BudgetAcceptDocumentNecessary } from "./budget/budagetAccepts/BudgetAcceptDocumentNecessary";

export default {
    TermsAccept,
    CreateLambdaComplement,
    CreateTablesComplement,
    TableComplement,
    TableGridLambda,
    ValidatePage,
    TableNewDownloader,
    SelectCustom,
    TransferRequestCustom,
    ReportCustom,
    CheckTreePickerUserRestrict,
    CheckTreePickerProjectUser,
    TransferRequestCollectUser,
    TransferRequestUser,
    GraphicCustom,
    BudgetAcceptDocumentNecessary
// EXPORTAR O COMPONENTE
};
