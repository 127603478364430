import axios from "axios";
import { Event } from "../Event";

export class UserEvents extends Event {
  public onLoad = (value: any, setField: any, setState: any, formValues: any) => {


    if (this.globalProps.values.user_fk_role_id == 6) {
      if (this.globalProps.values.user_fk_role_id == 6) {
        this.setField("user_merchandise_limit_value", {
          required: true,
          hidden: false
        })
      }
    }

    // console.log("globalProps", this.globalProps);
    if (!this.globalProps.values.user_id) {
      this.setFieldValue('user_name', '‎');
      this.setFieldValue('user_password', "999");
    }

  }
}
export class UserFkRoleIdEvents extends Event {
  onChange = (value: any, setField: any, setState: any) => {
    // if (value == 6) {
    //   if (this.globalProps.values.user_fk_role_id == 6) {
    //     this.setField("user_merchandise_limit_value", {
    //       required: true,
    //       hidden: false
    //     })
    //   }
    // }
    // this.setField("user_merchandise_limit_value", {
    //   hidden: false
    // })
  }
}
export class UserCepEvents extends Event {
  // onBlur = (value: any, setField: any, setState: any) => {
  //   if (value.length === 8) {
  //     setState("loadings", [
  //       "user_address_street",
  //       "user_address_neighborhood",
  //       "user_fk_state_id",
  //       "user_fk_city_id"
  //     ]);

  //     axios
  //       .get("https://viacep.com.br/ws/" + value + "/json/")
  //       .then((res: any) => {
  //         // console.log("consulta cep", res.data);

  //         if (res.data) {
  //           setField("user_address_street", res.data.rua);
  //           setField("user_address_neighborhood", res.data.bairro);

  //           console.log("Valor do res api cep", res.data)

  //           var stateId: any = undefined;
  //           axios
  //             .get("/api/v1/states/select", {
  //               params: {
  //                 state: res.data.uf
  //               },
  //             })
  //             .then((res: any) => {
  //               const { items } = res.data;
  //               console.log("items states", items)
  //               const { label, value } = items[0] ?? undefined;
  //               stateId = value;
  //               if (label && value) {
  //                 setField("user_fk_state_id", { label, value: value.toString() });
  //               }
  //             })
  //             .catch((e) => { console.log("Valor do erro", e) })
  //             .finally(() => {
  //               // if (this.globalProps.match.url.includes('/add')) {
  //               axios
  //                 .get("/api/v1/cities/select", {
  //                   params: {
  //                     state: stateId,
  //                     city_ibge_code: res.data.ibge
  //                   },
  //                 })
  //                 .then((res) => {
  //                   var { items } = res.data;
  //                   var { label, value } = items[0] ?? undefined;
  //                   // console.log("label e values", label, value);
  //                   if (label && value) {
  //                     this.setFieldValue("user_fk_city_id", value);
  //                   }
  //                 })
  //                 .catch((e) => { })
  //                 .finally(() => {
  //                   setState("loadings", []);
  //                 });
  //               // }
  //             });

  //           setField("user_address_street", res.data.logradouro);
  //           setField("user_address_neighborhood", res.data.bairro);
  //           // setField("user_email", res.data.email);
  //         }
  //       })
  //       .catch((e) => {
  //         setState("loadings", []);
  //       })
  //       .finally(() => {
  //         setState("loadings", []);
  //       });
  //   }
  // };
}
