import dayjs from "dayjs";
import { Component, ReactNode } from "react";
import { DatePicker } from 'rsuite';
import { locale, localeMonths } from "../../utilities/locale/DateRangePicker";

interface IDatePickerProps {
    onChange: (value: any) => void;
    value: Date | any;
    readonly?: boolean;
    format?: string;
    disabledDate?: any;
    useOnlyMonths?: any;
}

interface IDatePickerState {
    value: Date;
}

export class DatePickerComponent extends Component<IDatePickerProps, IDatePickerState> {
    state = {
        value: this.props.value
    }

    componentDidUpdate(props: any, state: any): void {
        if (props.value !== state.value) {
            this.setState({ value: props.value });
        }
    }
    handleOnChange = (value: Date) => {
        const formattedDate = this.props.useOnlyMonths
            ? dayjs(value).endOf('month').format('YYYY-MM-DD 23:59:00')
            : dayjs(value).subtract(3, 'hour').format('YYYY-MM-DD HH:mm:ss');

        this.props.onChange(formattedDate);
        this.setState({ value: value });
    }

    render(): ReactNode {
        return (
            <>
                <DatePicker
                    format={
                        this.props?.useOnlyMonths
                            ? "MM/YYYY"
                            : (this.props?.format ? this.props?.format : "DD/MM/YYYY")
                    }
                    locale={this.props?.useOnlyMonths === true ? localeMonths : locale}
                    value={this.state.value}
                    oneTap={this.props?.useOnlyMonths === true ? false : true}
                    onChange={this.handleOnChange}
                    onClean={() => this.props.onChange(null)}
                    disabled={this.props.readonly}
                    disabledDate={
                        this.props?.useOnlyMonths === true
                            ? date => {
                                const today = new Date();
                                if (!(date instanceof Date) || isNaN(date.getTime())) {
                                    return true;
                                }
                                return (
                                    date.getFullYear() < today.getFullYear() ||
                                    (date.getFullYear() === today.getFullYear() && date.getMonth() < today.getMonth())
                                );
                            }
                            : this.props.disabledDate
                    }
                    style={{ display: "flex" }}
                />
            </>
        );
    }
}
