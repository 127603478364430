import { Event } from "../../Event";

export class BudgetAcceptEvents extends Event {
    public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
        console.log("abri aqui :>", this)
        if ([5, 6].includes(this.globalProps.parentValues.budget_fk_charging_type_id)) {
            this.setField("budget_accept_description_merchandise", { hidden: true })
            this.setField("budget_accept_organ_anuante", { hidden: true })
        }
    }
}