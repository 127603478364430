import React from "react";
import { UserContext } from "../../components/authProvider/main";
// import KanbanPage from "./KanbanPage";
// import KanbanView from "./KanbanView";
import { Panel } from "rsuite";
import LogTableWithPagination from './TableLogPage'

export class LogGeneralIndex extends React.Component<any, {}> {
    static contextType = UserContext;



    render() {
        return (<>
            <div className="container" style={{ background: "white", marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "210px" }}>
                <div style={{ height: "100%", width: "100% " }}>
                    <LogTableWithPagination />
                </div>
            </div>
        </>)
    }
}