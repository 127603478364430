import React, { useState, useEffect } from "react";
import { Table, Input, DatePicker, SelectPicker, TreePicker, Button, Loader } from "rsuite";
import TablePagination from "rsuite/lib/Table/TablePagination";
import axios from "../../utilities/axios";
import dayjs from "dayjs";
import 'dayjs/locale/pt-br';

dayjs.locale('pt-br');

const { Column, HeaderCell, Cell } = Table;

const LogTableWithPagination = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [displayLength, setDisplayLength] = useState(10);

    // Estados dos filtros
    const [selectedUser, setSelectedUser] = useState(null);
    const [dateRange, setDateRange] = useState<[string | null, string | null]>([
        dayjs().subtract(30, "days").format("YYYY-MM-DD 00:00:00"),
        dayjs().format("YYYY-MM-DD 23:59:59")
    ]);
    const [actionType, setActionType] = useState(null);
    const [selectedScreen, setSelectedScreen] = useState("budgets");
    const [logId, setLogId] = useState("");
    const [users, setUsers]: any = useState([]);

    const locale = {
        sunday: "DOM",
        monday: "SEG",
        tuesday: "TER",
        wednesday: "QUA",
        thursday: "QUI",
        friday: "SEX",
        saturday: "SAB",
        ok: "Ok",
        today: "Hoje",
        yesterday: "Ontem",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
        formatDate: (date: any) => dayjs(date).format("DD/MM/YYYY"),
    };

    // 🔹 Função para buscar usuários via API
    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await axios.get("/api/v1/users/select");
            setUsers(response.data?.items || []);
        } catch (error) {
            console.error("Erro ao buscar usuários:", error);
        } finally {
            setLoading(false);
        }
    };

    // 🔹 Buscar logs ao carregar a página
    useEffect(() => {
        fetchUsers();
        fetchLogs();
    }, []);

    const fetchLogs = async () => {
        try {
            setLoading(true);
            const response = await axios.get("/api/v1/logs/get-logs");
            setLogs(response.data.response || []);
        } catch (error) {
            console.error("Erro ao buscar logs:", error);
        } finally {
            setLoading(false);
        }
    };

    // 🔹 Aplicar filtros
    const handleFilter = async () => {
        try {
            setLoading(true);
            const params = {
                log_fk_user_id: selectedUser,
                // log_fk_client_id: selectedUser,
                log_date_created_first: dateRange[0],
                log_date_created_second: dateRange[1],
                log_type: actionType,
                log_name_fk_table: selectedScreen,
                log_fk_table_id: logId
            };

            const response = await axios.get("/api/v1/logs/get-logs", {
                params: { params: params },
            });

            setLogs(response.data.response || []);
        } catch (error) {
            console.error("Erro ao buscar logs filtrados:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleClearFilters = () => {
        setSelectedUser(null);
        setDateRange([
            dayjs().subtract(30, "days").format("YYYY-MM-DD 00:00:00"),
            dayjs().format("YYYY-MM-DD 23:59:59")
        ]);
        setActionType(null);
        setLogId("");
        fetchLogs();
    };

    const total = logs.length;
    const startIndex = (activePage - 1) * displayLength;
    const paginatedLogs = logs.slice(startIndex, startIndex + displayLength);

    return (
        <div style={{ padding: 20 }}>
            <h3>Logs</h3>

            {/* 🔹 Loading */}
            {loading && <Loader backdrop content="Carregando..." vertical />}

            {/* 🔹 Filtros */}
            <div style={{ display: "flex", gap: "10px", marginBottom: "15px", flexWrap: "wrap" }}>
                {/* 🔹 Select de Usuário */}
                <TreePicker
                    data={users}
                    value={selectedUser}
                    onChange={setSelectedUser}
                    placeholder="Usuário"
                    style={{ width: 200 }}
                />

                {/* 🔹 DatePickers */}
                <DatePicker
                    defaultValue={dayjs(dateRange[0]).toDate()}
                    style={{ width: 200 }}
                    locale={locale}
                    format="DD/MM/YYYY"
                    onChange={(date) =>
                        setDateRange([
                            date ? dayjs(date).startOf("day").format("YYYY-MM-DD HH:mm:ss") : null,
                            dateRange[1],
                        ])
                    }
                    placeholder="Data Inicial"
                />

                <DatePicker
                    defaultValue={dayjs(dateRange[1]).toDate()}
                    style={{ width: 200 }}
                    locale={locale}
                    format="DD/MM/YYYY"
                    onChange={(date) =>
                        setDateRange([
                            dateRange[0],
                            date ? dayjs(date).endOf("day").format("YYYY-MM-DD HH:mm:ss") : null,
                        ])
                    }
                    placeholder="Data Final"
                />

                {/* 🔹 Select de Tipo de Ação */}
                <SelectPicker
                    data={[
                        { label: "Inclusão", value: "1" },
                        { label: "Alteração", value: "2" },
                        { label: "Exclusão", value: "3" },
                    ]}
                    value={actionType}
                    onChange={setActionType}
                    placeholder="Tipo da Ação"
                    style={{ width: 200 }}
                />

                {/* 🔹 Select de Tela (Bloqueado) */}
                <SelectPicker
                    data={[{ label: "Orçamentos", value: "budgets" }]}
                    value={selectedScreen}
                    style={{ width: 200 }}
                    disabled
                />

                {/* 🔹 Campo de ID */}
                <Input
                    value={logId}
                    onChange={setLogId}
                    placeholder="ID da tela"
                    style={{ width: 150 }}
                />

                {/* 🔹 Botão de Filtrar */}
                <Button appearance="primary" onClick={handleFilter}>
                    Filtrar
                </Button>
                <Button appearance="default" onClick={handleClearFilters}>
                    Limpar Filtros
                </Button>
            </div>

            {/* 🔹 Tabela */}
            <Table bordered cellBordered rowKey="log_id" data={paginatedLogs} autoHeight>
                {/* <Column width={50} align="center" resizable>
                    <HeaderCell>#</HeaderCell>
                    <Cell>{(_rowData: any, rowIndex: any) => total - (startIndex + rowIndex)}</Cell>
                </Column> */}


                <Column width={100} resizable align="center">
                    <HeaderCell>Tipo</HeaderCell>
                    <Cell>
                        {(rowData: any) => {
                            // Define as cores e os textos de acordo com log_type
                            const statusMap: any = {
                                1: { name: "Inclusão", color: "green" },
                                2: { name: "Alteração", color: "blue" },
                                3: { name: "Exclusão", color: "red" },
                            };

                            // Pega os valores ou um padrão caso o log_type seja inválido
                            const status = statusMap[rowData.log_type] || { name: "Desconhecido", color: "grey" };

                            return (
                                <div
                                    style={{
                                        backgroundColor: status.color,
                                        color: "#fff",
                                        padding: "2px 8px",
                                        borderRadius: "4px",
                                        display: "inline-block",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "100%",
                                        marginTop: "-4px",
                                        position: "relative",
                                        top: "4px",
                                    }}
                                >
                                    {status.name}
                                </div>
                            );
                        }}
                    </Cell>
                </Column>

                <Column width={150} resizable>
                    <HeaderCell>Usuário</HeaderCell>
                    <Cell dataKey="log_user_name" />
                </Column>

                <Column width={200} resizable>
                    <HeaderCell>Tabela</HeaderCell>
                    <Cell dataKey="log_name_fk_table" />
                </Column>

                <Column width={200} resizable>
                    <HeaderCell>ID da tela</HeaderCell>
                    <Cell dataKey="log_fk_table_id" />
                </Column>


                <Column width={250} resizable>
                    <HeaderCell>Campo</HeaderCell>
                    <Cell dataKey="log_name_field" />
                </Column>

                <Column width={150} resizable>
                    <HeaderCell>Valor Antigo</HeaderCell>
                    <Cell>
                        {(rowData: any) => {
                            const content = rowData.log_field_content_old;
                            if (!content) return "—";

                            const date = new Date(content);

                            if (!isNaN(date.getTime()) && content.includes(".000Z")) {
                                return dayjs(date).add(3, 'hour').format('DD/MM/YYYY HH:mm:ss');
                            }

                            return <div dangerouslySetInnerHTML={{ __html: content }} />;
                        }}
                    </Cell>
                </Column>

                <Column width={150} resizable>
                    <HeaderCell>Valor Novo</HeaderCell>
                    <Cell>
                        {(rowData: any) => {
                            const content = rowData.log_field_content_new;
                            if (!content) return "—";

                            const date = new Date(content);

                            if (!isNaN(date.getTime()) && content.includes(".000Z")) {
                                return dayjs(date).add(3, 'hour').format('DD/MM/YYYY HH:mm:ss');
                            }

                            return <div dangerouslySetInnerHTML={{ __html: content }} />;
                        }}
                    </Cell>
                </Column>

                <Column width={200} resizable>
                    <HeaderCell>Data</HeaderCell>
                    <Cell>
                        {(rowData: any) => dayjs(rowData.log_date_created).format("DD/MM/YYYY HH:mm:ss")}
                    </Cell>
                </Column>
            </Table>


            {/* 🔹 Paginação */}
            <TablePagination
                lengthMenu={[{ value: 10, label: 10 }, { value: 20, label: 20 }]}
                activePage={activePage}
                displayLength={displayLength}
                total={total}
                onChangePage={setActivePage}
                onChangeLength={(length: any) => {
                    setDisplayLength(length);
                    setActivePage(1);
                }}
            />
        </div>
    );
};

export default LogTableWithPagination;
