import React from "react";
import { UserContext } from "../../components/authProvider/main";
// import KanbanPage from "./KanbanPage";
// import KanbanView from "./KanbanView";
import { Panel } from "rsuite";
import KanbanLtlPage from "./KanbanLtlPage";
import KanbanLtlView from "./KanbanLtlView";

export class KanbanLtlIndex extends React.Component<any, {}> {
    static contextType = UserContext;



    render() {
        return (<>
            <div className="container" style={{ background: "white", marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }}>
                <Panel style={{ width: "100%" }}>
                    {[2, 3].includes(this.context?.data?.user_fk_role_id) ? (<KanbanLtlPage />)
                        :
                        <KanbanLtlView />
                    }
                </Panel>
            </div>
        </>)
    }
}