import React from "react";
import { Notification, Button, Input, InputGroup, Uploader, Checkbox, SelectPicker } from "rsuite";
import { AuthContext } from "../authProvider/main";
import _ from "lodash";
import mimeType from "mime-types";
import axios from "../../utilities/axios";
import classNames from "classnames";
import { getFontAwesomeIconFromMIME } from "../../utilities/iconMimeType";

export class FileInput extends React.Component<any, {}> {
    static contextType = AuthContext;

    public state = {
        files: this.props.value ?? [],
        isDirty: [], //[{name:'as',status:"finished"}]
        customValues: {},
        isLoading: [],
    };

    existingFiles = () => {

    }

    getParsedFileState = () => {
        var files: any = [];
        for (var i in this.state.files) {
            var file = this.state.files[i];
            if (file.file_name) {

                files.push({
                    file: { name: file.file_name },
                    values: file.values ?? {},
                    id: file.file_id,
                    fileKey: file.file_id,
                    thumbnail: file.file_thumbnail,
                    name: file.file_name,
                    status: "finished",
                    url: file.file_path.split("\\").join("/"),
                    size: file.file_size,
                });

            } else {
                files.push(file);
            }
        }
        // const filteredArray = files.filter(function(ele: any , pos: any){
        //     return files.indexOf(ele) == pos;
        // }) 

        // for(var i in files){
        //     var indiceOld = files[i];
        //     var novoArray = filteredArray[i];
        //     //console.log("Array novo Indice",novoArray);
        //     if(novoArray == undefined) {
        //         console.log("Olha",indiceOld);
        //         var valueExcluded = indiceOld;
        //         this.onRemove(valueExcluded);
        //     }
        // };
        //console.log("Array Antigo", files);
        //console.log("Array novo", filteredArray);
        return files;
    };

    getSize = (size: number) => {
        if (size) {
            var list = ["B", "KB", "MB", "GB", "TB"];
            var count = 0;
            while (size > 1024) {
                size = size / 1024;
                count++;
            }
            return size.toLocaleString("pt-BR", { maximumFractionDigits: 2 }) + list[count];
        }
        return 0;
        // (size / 1024).toLocaleString("pt-BR", { maximumFractionDigits: 2 })
    };

    onChangeInputValue = (value: string, campo: string, file: any) => {
        const isDirty: any = this.state.isDirty ?? [];
        const customValues: any = this.state.customValues ?? {};
        if (!customValues[file.id]) {
            customValues[file.id] = {};
        }
        customValues[file.id][campo] = value;
        isDirty.push(file.id);
        this.setState({ isDirty, customValues });
    };

    onSaveCustomValue = (defaultValues: any, file: any) => {
        console.log([file])
        const customValues: any = this.state.customValues ?? {};
        if (customValues[file.id]) {
            const values = { ...defaultValues, ...customValues[file.id] };
            const isLoading: any[] = [...this.state.isLoading];
            isLoading.push(file.id);
            this.setState({ isLoading });
            const promises: any[] = [];
            for (var i in values) {
                promises.push(
                    axios
                        .post("/api/v1/file/" + file.id + "/" + i, {
                            value: values[i],

                        })
                        .then((res) => { })
                        .catch(() => { }),
                );
            }
            Promise.all(promises)
                .then(() => {
                    Notification.success({
                        title: "Sucesso!",
                        description: "Arquivo atualizado com sucesso!",
                    });
                })
                .catch(() => { })
                .finally(() => {
                    const isLoading: any[] = [...this.state.isLoading];
                    const isDirty: any[] = [...this.state.isDirty];
                    isLoading.splice(isLoading.indexOf(file.id), 1);
                    isDirty.splice(isDirty.indexOf(file.id), 1);
                    let indexFile = _.findIndex(this.state.files, { file_id: file.id })
                    // if(file?.file_id){
                    if (indexFile == -1) indexFile = _.findIndex(this.state.files, { id: file.id })
                    if (indexFile >= 0) {
                        this.state.files[indexFile] = { ...this.state.files[indexFile], values: { ...values } }
                        this.props.onChange(this.state.files)
                    }


                    //  }
                    // this.setState({
                    //     files: {...this.state.files}
                    // })
                    this.setState({ isLoading, isDirty });
                });
        }
    };

    renderCustomValues = (values: any, file: any) => {
        var output = [];
        const id: any = file.id;
        const isDirty: any = this.state.isDirty;

        if (this.props?.showQuestions == "true" || this.props?.showQuestions == true) {
            if (!values?.descricao) {
                output.push(
                    <div key="descricao" className="mr-4  mt-3 mb-3">
                        <InputGroup>
                            <InputGroup.Addon>Legenda</InputGroup.Addon>
                            <Input disabled={!id} onChange={(value: string) => this.onChangeInputValue(value, "descricao", file)} />
                        </InputGroup>
                    </div>,
                );
            }
            // if(!values?.orcamento){
            //     output.push(
            //      <div key="orcamento" style={{display:"inline-flex"}} className="mr-4  mt-3 mb-3"> 
            //     <label>Utilizar Na Proposta</label>
            //     <SelectPicker disabled={!id} placeholder={""} defaultValue={"false"} onChange={(value: string) => this.onChangeInputValue(value, "orcamento", file)} data={[{label:"Não",value:"false"},{label:"Sim",value:"true"}]} />

            //     </div>,
            //   )}
        }


        if (values) {
            if (values["validade"]) {
                output.push(
                    <div key="validade" className="mr-4 mt-3 mb-3">
                        <InputGroup>
                            <InputGroup.Addon>Validade</InputGroup.Addon>
                            <Input disabled={!file.id} onChange={(value: string) => this.onChangeInputValue(value, "validade", file)} defaultValue={values["validade"]} />
                        </InputGroup>
                    </div>,
                );
            }
            if (values["descricao"]) {
                output.push(
                    <div key="descricao" className="mr-4  mt-3 mb-3">
                        <InputGroup>
                            <InputGroup.Addon>Legenda</InputGroup.Addon>
                            <Input disabled={!file.id} onChange={(value: string) => this.onChangeInputValue(value, "descricao", file)} defaultValue={values["descricao"]} />
                        </InputGroup>
                    </div>,
                );
            }
            if (this.props?.showQuestions == true) {
                // if (values["orcamento"]) {
                //     output.push(
                //         <div key="orcamento" style={{display:"inline-flex"}} className="mr-4  mt-3 mb-3"> 
                //             <label>Utilizar Na Proposta</label>
                //             <SelectPicker placeholder={"Orçamento"} defaultValue={values["orcamento"]} disabled={!file?.id}  onChange={(value: string) => this.onChangeInputValue(value, "orcamento", file)} data={[{label:"Não",value:"false"},{label:"Sim",value:"true"}]} />

                //         </div>,
                //     );
                // }
            }
        }
        if (isDirty.includes(id)) {
            const loadings: any[] = this.state.isLoading;
            output.push(
                <div key="confirm" className="mr-4 mt-3">
                    <Button onClick={() => this.onSaveCustomValue(values, file)} loading={loadings.includes(id)} color={"green"}>
                        <i className="fas fa-check fa-fw"></i>
                        Salvar
                    </Button>
                </div>,
            );
        }

        return <div className="">{output}</div>;
    };

    renderFile = (file: any, fileElement: any) => {
        const fileType: any = mimeType.lookup(file.name);
        var imageHtml = null;
        var isImage = false;
        // file.url = "/storage/uploads/17-11-2021/1637148740_WhatsApp Image 2021-10-26 at 11.58.42 (1).jpeg";
        if (fileType?.toString().includes("image") && file.url) {
            isImage = true;
            imageHtml = <img alt="thumbnail" src={file.thumbnail ?? file.url} className="img-fluid" />;
        }
        // //(file);
        return (
            <div className={classNames("file-uploaded", { isImage })}>
                <div className="file-header">
                    <div className="file-name">
                        {file.name ?? "Oops.."} {this.renderCustomValues(file.values, file)}
                    </div>
                    <div className="file-thumbnail">{imageHtml}</div>
                    {isImage !== true && (
                        <div className="file-icon">
                            <i className={classNames("fal", fileType ? getFontAwesomeIconFromMIME(fileType) ?? "fa-file" : "fa-file")}></i>
                        </div>
                    )}
                </div>

                {/* <div>{JSON.stringify(file.values) ?? 'A'}</div> */}
                {file.url && (
                    <div className="file-meta">
                        {file.size > 0 && <span className="mr-9 o-4">{this.getSize(file.size)}</span>}
                        <a rel="noreferrer" target="_blank" className="mr-9" href={file.url}>
                            Visualizar <i className="fas fa-eye"></i>
                        </a>
                        <a rel="noreferrer" target="_blank" download href={file.url}>
                            Baixar <i className="fas fa-download"></i>
                        </a>
                    </div>
                )}
            </div>
        );
    };



    public fileQueue: any = [];

    onSuccessEachFile = (files: any, index = 0) => {
        let stateFiles = [...this.state.files];
        if (files[index]) {
            let file = files[index];
            var latestFile = stateFiles.find((item: any) => item.name == file.meta.file_name);
            if (latestFile) {

                latestFile.id = file.id;
                latestFile._key = latestFile.fileKey;
                latestFile.url = file.path;
                latestFile.status = "success";
                latestFile.file = {
                    name: "teste",
                };
                stateFiles = stateFiles.map((item) => {
                    if (item.name === file.meta.file_name) {
                        return latestFile;
                    }
                    return item;
                })
                // stateFiles[index] = latestFile;
                this.setState({ files: stateFiles }, () => {
                    if (index < files.length - 1) {
                        this.onSuccessEachFile(files, index + 1);
                    }
                });
                if (this.props.onChange) {
                    this.props.onChange(stateFiles);
                }
                if (this.props.onBeforeUpload) {
                    this.props.onBeforeUpload(latestFile);
                }
            }
        }
    };

    onSuccess = (res: any) => {
        if (Array.isArray(res) === false) {
            res = [res];
        }
        this.fileQueue = res;
        // this.setState({files: })
        this.onSuccessEachFile(this.fileQueue);
        // this.fileQueue.push(res);
        // var files = this.state.files;
    };

    onChange = (files: any) => {
        const unblobedFiles = _.cloneDeep(files);
        for (var i in unblobedFiles) {
            delete unblobedFiles[i].blobFile;
        }
        if (this.props.onChange) {
            this.props.onChange(unblobedFiles);
        }
        this.setState({ files });
    };

    getIndex = (key: string) => {
        for (var i in this.latestFiles) {
            var file = this.latestFiles[i];
            if (file.file_id === key || file.fileKey === key) {
                return parseInt(i);
            }
        }
        return -1;
    };
    public latestFiles: any = this.state.files;

    shouldComponentUpdate(props: any, state: any) {
        if (state.files !== this.state.files) {
            this.latestFiles = this.state.files;
        }
        return true;
    }

    onRemove = (file: any) => {
        console.log({ file })
        const index = this.getIndex(file.fileKey);
        // console.log(file)
        //(this.latestFiles);
        var regExp = /[a-zA-Z]/g;
        var testString = file.fileKey;
        axios
            .post("/api/v1/file/delete/" + (regExp.test(testString) ? file.id : file.fileKey))
            .then((res) => { })
            .catch((e) => {
                const files: any[] = this.state.files;
                files.splice(index, 0, file);
                this.setState({ files });
            });
        return this.state.files;
    };

    onUpload = (file: any) => {
        //(file,this.props,this.props.onBeforeUpload);
    };

    render() {
        return (
            <div className="file-upload-input">
                <Uploader
                    draggable
                    listType={"text"}
                    renderFileInfo={this.renderFile}
                    className="mb-6"
                    fileList={this.getParsedFileState()}
                    // onUpload={}                    
                    multiple={this.props.multiple} // multiplefiles - false para desabilitar
                    showQuestions={this.props.showQuestions}
                    // onUpload={this.onUpload
                    disabled={this.props.readonly}
                    removable={!this.props.readonly}
                    onChange={this.onChange}
                    onSuccess={this.onSuccess}
                    onRemove={this.onRemove}
                    action={"/api/v1/upload?guid=" + this.props.guid + "&relation=" + this.props.relation + "&field=" + this.props.name}
                    headers={{
                        Authorization: "bearer " + this.context.token,
                    }}>
                    <div style={{ lineHeight: 4 }}>Clique ou arraste arquivos aqui.</div>
            </Uploader>
                {/* {JSON.stringify(this.props)} */}
                <div className="thumbnail-place"></div>
            </div>
        );
    }
}
