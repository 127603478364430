import { Event } from "../Event";
import axios from "../../utilities/axios";
import { Notification } from "rsuite";

export class UserCpfCnpjEvents extends Event {
    onBlur = async (value: any, setField: any, setState: any) => {
        // if (value.length === 11) {
        //     this.setField("user_name", {
        //         width: '6'
        //     })
        //     this.setField("user_fantasy_name", {
        //         hidden: true,
        //         required: false
        //     })
        // }
        // if (value.length === 14) {
        //     this.setField("user_fantasy_name", {
        //         width: '4',
        //         hidden: false,
        //         required: true,
        //         // width: 6
        //     })
        //     this.setField("user_name", {
        //         width: '5',
        //     })
        // }
        this.setState({
            loadings: [
                "user_cpf_cnpj",
                "user_name",
                "user_fantasy_name",
                "user_phone_number",
                "user_email",
                "user_fk_state_id",
                "user_fk_city_id",
                "user_cep",
                "user_address_street",
                "user_address_number",
                "user_address_complement",
                "user_address_neighborhood"
            ]
        });
        await axios
            .get("/api/v1/consulta-cnpj/" + value)
            .then(async(res) => {
                var { data } = res.data;

                if (data.status === "ERROR") {
                    setState("errors", {
                        user_cnpj_cpf: res.data.error ?? "CNPJ Inválido",
                    });
                    setState("loadings", []);
                    return;
                }
                if (data.status !== 'ERROR') {
                    // ////console.log(typeof(data.atividade_principal[0].code))
                    // var newNumber = data.atividade_principal[0].code.replace(/[^0-9]/g, '')
                    // ////console.log(typeof(newNumber))

                    // axios.get("/api/v1/cnaes/select", {
                    //     params: {
                    //         search: newNumber
                    //     }
                    // }).then((res) => {
                    //     ////console.log(res.data)
                    //     if(res) {
                    //         //setField("user_cnaes", true)
                    //     }
                    // })

                    var stateID: string = '';
                    var idCity: string = '';

                    setField("user_name", data.nome);

                    if (data.fantasia.length) {
                        if (value.length === 11) {
                            setField("user_fantasy", data.fantasia.length ? data.fantasia : data.nome);
                        }
                        if (value.length === 14) {
                            setField("user_fantasy_name", data.fantasia.length ? data.fantasia : data.nome);
                        }
                    } else {
                        setField("user_fantasy", "");
                    }
                    await axios
                        .get("/api/v1/states/select", {
                            params: {
                                state: data.uf,
                            },
                        })
                        .then((res) => {
                            console.log("Valor do res state", res)
                            const { items } = res.data;
                            const { label, value } = items[0] ?? undefined;
                            stateID = value;
                            if (label && value) {
                                setField("user_fk_state_id",  stateID);
                            }
                        })
                        .catch((e) => { })
                        .finally(async() => {
                            await axios
                                .get("/api/v1/cities/select", {
                                    params: {
                                        search: data.municipio,
                                        state: stateID,
                                    },
                                })
                                .then((res: any) => {
                                    const { items } = res.data;
                                    const { label, value } = items[0] ?? undefined;
                                    idCity = value;
                                    console.log("Res cidade", idCity)
                                    if (label && value) {
                                        setField("user_fk_city_id", idCity);
                                    }
                                })
                                .catch((e) => { })
                                .finally(() => {
                                    // setState("loadings", []);
                                });
                        });


                    setField("user_cep", data.cep.split(".").join("").split("-").join(""));
                    setField("user_address_street", data.logradouro);
                    // if (data.numero !== "S/N" && data.numero !== "SN") {
                    setField("user_address_number", data.numero);
                    // } else {
                    //     setField("user_number", undefined);
                    // }
                    setField("user_address_neighborhood", data.bairro);
                    setField("user_address_complement", data.complemento);
                    // setField("user_principal_phone", data.telefone.split(" ").join(""));
                    setField("user_email", data.email);
                    setField("user_phone_number", data.telefone);
                    // setField("user_site", data.);
                }
            })
            .catch((e) => {
                setState("loadings", []);
            })
            .finally(() => {
                setState("loadings", []);
            });
        this.setState("loadings", ["user_fk_state_id", "user_fk_city_id"])
        setState("loadings", []);

    }
}
